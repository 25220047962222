@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply flex px-5 py-1 items-center justify-center bg-gray-100 rounded hover:bg-blue-200 border border-gray-300 hover:border-blue-300;
  }
  .btn-primary.active {
    @apply bg-sky-700 text-white hover:bg-sky-600;
  }
  .btn-accordion {
    @apply flex w-full px-5 py-2 items-center justify-center bg-white hover:bg-gray-50;
  }
  .btn-text {
    @apply px-2 py-1 hover:text-blue-600 disabled:text-slate-400;
  }
  .a-primary {
    @apply text-blue-600 hover:text-blue-800 disabled:text-slate-400;
  }
  .text-field {
    @apply border p-1 rounded focus:outline-none;
    @apply border-slate-300 focus:border-slate-800;
  }
  /* :not(.text-field.error) {
  } */
  .text-field.error {
    @apply border-red-300 focus:border-red-600;
  }
}

body {
  /* font-family: nunito sans, arial, sans; */
  /* background: url("./assets/backgrounds/bg2.jpg") center center fixed; */
  @apply bg-slate-700;
  overflow-y: scroll;
}
